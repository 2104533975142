.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #990000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #990000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  
}
.popup_inner {
  position: absolute;
width: 25%;
height: auto;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: none;
  background: white;
  border-radius: 5px;
 box-sizing: border-box;
}

.popupUpdate {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  
}
.popup_innerUpdate {
  position: absolute;
width: 25%;
height: auto;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: none;
  background: white;
  border-radius: 5px;
 box-sizing: border-box;
}


table {
  text-align: center;
  counter-reset: page;
}


@media screen {
  .page-number:before {
    counter-increment: page;
    content: counter(page) ".";
  }
}