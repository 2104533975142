body, html {
    height: 100%;
    margin: 0;
}

.login{
    // background-image: url(../static/login-bg.jpg);
background-color: red;
    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}